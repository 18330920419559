import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LeaderBoards from "./Pages/LeaderBoards/LeaderBoards";
import Layout from "./Components/Layout";

function Routes() {

    return (
        <BrowserRouter>
            <Route render={(props)=>(
                <Layout {...props}>
                    <Switch>
                        <Route path="/" exact component={LeaderBoards}/>
                        <Route path="/twitter-leaderboard" exact component={LeaderBoards}/>
                        <Route component={LeaderBoards}/>
                    </Switch>
                </Layout>
            )}/>
        </BrowserRouter>
    )
}

export default Routes;