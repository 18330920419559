import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import NavBar from "../../Components/NavBar/NavBar";
import logo from "../../assets/header/newLogo.svg";
import discordFooter from "../../assets/footer/discord.svg";
import twitterFooter from "../../assets/footer/twitter.svg";
import instagram from "../../assets/header/instagram.svg";
import mobileErrorIcon from "../../assets/sales/mobileError.svg";
import connectArrow from "../../assets/sales/connectArrow.svg";
import axios from 'axios';
import './LeaderBoards.scss';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: '60vh',
    background:'#ffffff'
  },
  TablePagination:{
    background:'#ffffff'
  }
});

export default function LeaderBoards(props) {
  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [backUp, setBackUp] = React.useState([]);
  const [leaderboardsDate, setLeaderboardsDate] = React.useState();
  const [userAddress, setUserAddress] = React.useState("");
  const [navBarStyle, setNavBarStyle] = React.useState("false");
  const [activeIndex, setActiveIndex] = React.useState(7);
  const menuItems = [
    { name: "HOME", ref: "menuBar" },
    { name: "ABOUT", ref: "about" },
    { name: "CITIZENSHIP BENEFITS", ref: "citizenshipBenefits" },
    { name: "OUR ROADMAP", ref: "roadmap" },
    { name: "MEET OUR TEAM", ref: "teamSection" },
    { name: "FAQ", ref: "faq" },
    { name: "BUY A BULL", ref: "buy" },
    { name: 'LEADER BOARD', 'ref': 'LeaderBoards' }
  ];

  const [order, setOrder] = React.useState('dsc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);

  const createSortHandler = (property,order) => (event) => {
    
    let newdata;
    if(order == 'asc'){
          newdata = backUp.sort((a, b) => {
                let fa = a[property],
                fb = b[property];
                if (Number(fa) < Number(fb)) {
                    return -1;
                }
                if (Number(fa) > Number(fb)) {
                    return 1;
                }
                return 0;
          });
    }else{
        newdata = backUp.sort((a, b) => {
                let fa = a[property],
                fb = b[property];
                if (Number(fa) > Number(fb)) {
                    return -1;
                }
                if (Number(fa) < Number(fb)) {
                    return 1;
                }
                return 0;
          });
    }
    setOrder(order == 'asc' ? 'dsc' : 'asc')
    setRows([...newdata]);
  };

  const openNav = (isOpen) => {
    setNavBarStyle(isOpen);
  };

  const scrollToSection = (e, index) => {
    setActiveIndex(index);
    if(e == 'buy'){
      props.history.push("/bulls");
    }else{
      props.history.push("/home");  
    }
    openNav("false");
  };

  const navigateToMainSite = () => {
    history.push("/");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "position", label: "Position", minWidth: 100, align: "center"},
    { id: "twitterHandle", label: "Twitter Handle", minWidth: 100, align: "center" },
    { id: "BullPFPScore", label: "Bull PFP", minWidth: 100, align: "center" },
    { id: "BullFollow", label: "Bull Follow", minWidth: 100, align: "center" },
    { id: "engagmentScore", label: "Engagment", minWidth: 100, align: "center",},
    { id: "WeeklyScore", label: "Weekly Score", minWidth: 100, align: "center",},
    { id: "WeeklyToken", label: "Weekly Tokens", minWidth: 100, align: "center"},
    { id: "TotalTokenRewards", label: "Total Token Rewards ($BULL)", minWidth: 100, align: "center"},
  ];

  useEffect(() => {
    setLeaderboardsDate('2021/10/30');
    //setLeaderboardsDate(moment.utc().subtract(1, 'days').format('YYYY/MM/DD'));
    
    axios.get('https://leaderboardsfetchdata.herokuapp.com/getData')
      .then(data => {
        
        let res = data.data;
        let newArray = [];

        let all_users_total_score = 0;
        for(let i=0;i<res.length;i++){
          all_users_total_score += res[i].Total_per_user;  
          
          let ProfilePicPoints = res[i].ProfilePicPoints == 20 ? 50 : res[i].ProfilePicPoints;
          
          newArray.push({
            position:i+1,
            twitterHandle:res[i].Screen_name,
            BullPFPScore:ProfilePicPoints,
            BullFollow:res[i].BullFollowBull,
            engagmentScore: res[i].Engagement_Score,
            WeeklyScore: res[i].Total_per_user.toFixed(2),
            WeeklyToken: res[i].Current_Week_rewards.toFixed(2),
            TotalTokenRewards: res[i].TotalBullRewards_ForAll_Days.toFixed(2)
          });

          if(i == res.length -1){
            setRows(newArray); 
            setBackUp(newArray);
          }
        }
        
      })

  },[])

  const searchByName = (e) => {    
    
    let newdata = backUp.filter(item => {
      let a = item.twitterHandle.toLowerCase();
      let b = e.target.value.toLowerCase();
      return a.indexOf(b) > -1;
    })
    setRows(newdata); 
  }

  return (
    <React.Fragment>
      <div className={"NavBarNew fixed"}>
          <div className="brandLogo" onClick={() => navigateToMainSite()}>
            <img src={logo} className="logo" alt="logo"/>
            <div className={"brandName"}>Citizens of BulliEver Island </div>
          </div>
          <div className="socialIcons">
            <ul>
              {userAddress !== "" && (
                <li>
                  <button className="connectButton">{userAddress}</button>
                </li>
              )}
              <li>
                  <a rel="noreferrer" href="https://discord.com/invite/KudZsHeJqM" target="_blank"><img src={discordFooter} alt="discord"/></a>
              </li>
              <li>
                  <a rel="noreferrer" href="https://twitter.com/BullieverIsland" target="_blank"><img src={twitterFooter} alt="twitter"/></a>
              </li>
              <li className="youtubeIcon">
                  <a rel="noreferrer" href="https://www.instagram.com/bullieverisland/" target="_blank"><img src={instagram} alt="instagram"/></a>
              </li>
            </ul>
          </div>
          

          <div
            className={
              "overlayMenu " +
              (navBarStyle === "true" ? "openNavBar" : "closeNavBar")
            }
          >
            <div className="menuClose" onClick={(e) => openNav("false")}>
              &times;
            </div>
            <div className="overlayMenu-content">
              {menuItems.map((item, index) => (
                <ul key={index}>
                  <li className={activeIndex === index ? "active" : ""}>
                    <div className="link" onClick={(e) => scrollToSection(item.ref, index)}>
                      {item.name}
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      <div className="LeaderBoards">
      <div className="hd">
       <div className="LeaderBoardsDate">
        {leaderboardsDate} Leaderboard
       </div>
       <div class="sb-example-1">
         <div class="search">
            <input type="text" class="searchTerm" placeholder="SEARCH BY TWITTER HANDLE" onInput={(e)=>searchByName(e)}/>
            <button type="submit" class="searchButton">
              <SearchIcon className=""/>
           </button>
         </div>
      </div>
       {/*<div>
        <input type="search" placeholder="SEARCH BY TWITTER HANDLE" className="searchBox"/>
        <SearchIcon className="searchIcon"/>
       </div>*/}
      </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    className="tableHeader"
                    sortDirection={orderBy === column.id ? order : false}
                  >
                  {column.id == 'twitterHandle' ? (<React.Fragment>{column.label}</React.Fragment>) : (
                                           <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'asc'}
                                              onClick={createSortHandler(column.id,order)}
                                            >
                                              {column.label}
                                              {orderBy === column.id ? (
                                                <span className={classes.visuallyHidden}>
                                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                              ) : null}
                                            </TableSortLabel>
                                           )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.label}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.TablePagination}
        />
        </div>
    </React.Fragment>
  );
}
