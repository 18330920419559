import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import logo from "../../assets/header/newLogo.svg";
import discordFooter from "../../assets/footer/discord.svg";
import twitterFooter from "../../assets/footer/twitter.svg";
import instagram from "../../assets/header/instagram.svg";
import './NavBar.scss';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { BULLAbis } from "../../abis/BULLAbis";
const { ethereum } = window;
const Web3 = require("web3");
const NFTAddress = "0x329fa32F6520FB67Bb3C1fc3a6909BEb8239544c";
const buttonTokenAddress = '0x5824e2d508da5055c12046b0155cfd83d4b106f9';
export default function NavBar(props) {
    const history = useHistory();
    const [navBarStyle, setNavBarStyle] = React.useState('false');
    const [activeIndex, setActiveIndex] = React.useState(0);
    const menuItems = [
        { name: 'HOME', 'ref': 'menuBar' },
        { name: 'ABOUT', 'ref': 'about' },
        { name: 'CITIZENSHIP BENEFITS', 'ref': 'citizenshipBenefits' },
        { name: 'OUR ROADMAP', 'ref': 'roadmap' },
        { name: 'FAQ', 'ref': 'faqSection' },
        { name: 'MEET OUR TEAM', 'ref': 'teamSection' },
        { name: 'MY BULLS', 'ref': 'myBulls' },
        { name: 'LEADER BOARD', 'ref': 'LeaderBoards' }
    ];
    const [userAddress, setUserAddress] = React.useState("");
    const [wrongNetworkError, setWrongNetworkError] = React.useState(false);
    
    const [userRewards, setUserRewards] = React.useState(0);
    const [userRewardsInWallet, setUserRewardsInWallet] = React.useState();
    
    async function loadContract() {
        return await new window.web3.eth.Contract(BULLAbis, NFTAddress);
    }
    
    let contract = null;
    let methods = null;

    const checkHowManyBullsIHave = async () => {
        checkWalletConnected();
        contract = await loadContract();
        methods = await contract.methods;
        methods.balanceOf(window.web3.currentProvider.selectedAddress)
          .call()
          .then((response) => {
            checkWhichBullIHave(response);
          });
    };
    const checkWalletConnected = (e) => {
        if (window.web3) {
          window.web3 = new Web3(window.web3.currentProvider);
          window.ethereum.enable();
          return true;
        }
        return false;
    };
    const checkWhichBullIHave = async (count) => {
        let promises = [];
        for (let i = 0; i < count; i++) {
          promises.push(methods.tokenOfOwnerByIndex(window.web3.currentProvider.selectedAddress, i).call());
        }
        try {
          const tokenIds = await Promise.all(promises);
          getTotalRewards(tokenIds);
        } catch (error) {
          console.log(error);
        }
    };
    
    const getTotalRewards = async (tokenIds) => {
        methods.getTotalRewards(tokenIds)
        .call({ from: window.web3.currentProvider.selectedAddress })
        .then((response) => {
            response = response / Math.pow(10, 18);
            response = response.toFixed(2);
            setUserRewards(response);
        });
    };
    useEffect(() => {
        checkWalletConnectedOnPageLoad();
    }, [])
    const disconnectWallet = async () => {
        const walletAddress = await window.ethereum.request({method: "wallet_switchEthereumChain",params:[]});
          await window.ethereum.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {}
              }
            ]
          });
    }

    

    const checkWalletConnectedOnPageLoad = async () => {
        if (ethereum) {
            await ethereum.request({ method: "eth_accounts" }).then((response) => {
                getChainId(response);
            });
        } else {}
    };

    ethereum?.on("chainChanged", (_chainId) => {
        window.location.reload();
    });

    ethereum?.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
            setUserAddress("");
        } else {
            window.location.reload()
        }
    });
    const setUserAddressFunction = () => {
        let responseString = window.web3.currentProvider.selectedAddress;
        let splittedAddress = responseString.substring(0, 7) + "..." + responseString.substring(responseString.length - 3);
        setUserAddress(splittedAddress);
      };


    const getChainId = async (userAddress) => {
        ethereum.request({ method: "eth_chainId" }).then((response) => {
            if (response === '0x1') {
                if (userAddress.length > 0) {
                    setUserAddressFunction();
                    checkHowManyBullsIHave();
                    myWalletNalace();
                }
            } else {
                if (userAddress.length > 0) {
                    setWrongNetworkError(true);
                }
            }
        });
    };

    const connectToMetaMask = async () => {
        if (ethereum) {
          await ethereum.request({ method: "eth_requestAccounts" }).then((response) => {
              getChainId(response);
            },(error) => {
            }
          );
        } else {
          return false;
        }
    };

    const myWalletNalace = async () => {
        checkWalletConnected();
        await new window.web3.eth.Contract(BULLAbis, buttonTokenAddress).methods.balanceOf(window.web3.currentProvider.selectedAddress).call().then(res => {
          res = res / Math.pow(10, 18);
          res = Math.trunc(res);
          setUserRewardsInWallet(Number(res));
         })
    }

    const getTotalUserBalance = () => {
        let amount = Number(userRewardsInWallet) + Number(userRewards);
        return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g,'$&,');
    }

    const openNav = (isOpen) => {
        setNavBarStyle(isOpen);
    }

    const scrollToSection = (e, index) => {
        setActiveIndex(index);
        if (e === 'buy') {
            history.push("/sales");
        } else if(e == 'myBulls'){
            history.push("/bulls");
        }else if(e == 'LeaderBoards'){
            history.push("/twitter-leaderboard");
        }else {
            props.scrollToSection(e);
        }
        openNav('false');
    }

    const buyABullNavigation = () => {
        window.location.replace('https://sale.bullieverisland.com/');
    }
    
    const convertIntoNumberFormat = (amount) => {
        return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g,'$&,');
    }
    return (
        <React.Fragment>
                <div className={'NavBarNew '+ props.position}>
                    <div className="brandLogo">
                        <img src={logo} className={'logo ' + (props.headerOpacity === 'showTitle' ? 'hideTitle' : 'showTitle')} alt="logo" onClick={(e) => scrollToSection('menuBar',0)}/>
                        <div className={'brandName ' + (props.headerOpacity === 'showTitle' ? 'showTitle' : 'hideTitle')}>Citizens of BulliEver Island </div>
                    </div>
                    <div className="socialIcons">
                        <ul>
                            <li>
                            {wrongNetworkError ? (
                                    <button className="wrongNetworkButton">Wrong Network</button>
                                ): (
                                    <React.Fragment>
                                    {userAddress !== "" ? (
                                                           <React.Fragment>
                                                                <button className="userAddressButton" onClick={()=>scrollToSection('myBulls',0)}><span><span className="symbol">$</span>BULL </span> {getTotalUserBalance()}</button>
                                                           </React.Fragment>
                                                           
                                                            ): (
                                                            <button className="connectButton" onClick={()=> connectToMetaMask()}>{userAddress !== "" ? userAddress : 'Connect Wallet'}</button>
                                                            )
                                    }
                                    </React.Fragment>
                                )
                                
                            }
                            </li>
                            <li>
                                <a rel="noreferrer" href="https://discord.com/invite/KudZsHeJqM" target="_blank"><img src={discordFooter} alt="discord"/></a>
                            </li>
                            <li>
                                <a rel="noreferrer" href="https://twitter.com/BullieverIsland" target="_blank"><img src={twitterFooter} alt="twitter"/></a>
                            </li>
                            <li className="youtubeIcon">
                                <a rel="noreferrer" href="https://www.instagram.com/bullieverisland/" target="_blank"><img src={instagram} alt="instagram"/></a>
                            </li>
                        </ul>
                    </div>
                    
                    {/*<span className="menuIcon" onClick={(e) => openNav('true')}>&#9776;</span>*/}
                    
                    <div className={'overlayMenu ' + (navBarStyle === 'true' ? 'openNavBar' : 'closeNavBar')}>
                      <div className="menuClose" onClick={(e) => openNav('false')}>&times;</div>
                      <div className="overlayMenu-content">
                      {menuItems.map((item,index) => (
                        <ul key={item.ref}>
                            <li className={activeIndex === index ? 'active' : ''}>
                                <div className="link" onClick={(e) => scrollToSection(item.ref,index)}>{item.name}</div>
                            </li>
                        </ul>
                      ))}
                      </div>
                    </div>

                </div>
            

                {/*<Navbar collapseOnSelect="true" expand="lg" className={'NavBarNew '+ props.position} onToggle={() => toggleClicked()}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <div className="brandLogo">
                            <img src={logo} className="logo" />
                            <div className={'brandName ' + (props.headerOpacity == 'showTitle' ? 'showTitle' : 'hideTitle')}>Citizens of BulliEver Island </div>
                        </div>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <div className="menuItem">
                                <ul>
                                    <li className={activeMenuItem == 'home' ? 'active' : ''}>
                                        <a onClick={(e) => scrollToSection('menuBar')}>Home</a>
                                    </li>
                                    
                                    <li className={activeMenuItem == 'about' ? 'active' : ''}>
                                        <a onClick={(e) => scrollToSection('about')}>About</a>
                                    </li>
                                    
                                    <li className={activeMenuItem == 'roadmap' ? 'active' : ''}>
                                        <a onClick={(e) => scrollToSection('roadmap')}>Roadmap</a>
                                    </li>
                                    
                                    <li className={activeMenuItem == 'teamSection' ? 'active' : ''}>
                                        <a onClick={(e) => scrollToSection('teamSection')}>Team</a>
                                    </li>
                                </ul>
                            </div>
                        </Navbar.Collapse>
                </Navbar>*/}
            </React.Fragment>
    )

}